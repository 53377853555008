import { INavigationItem } from './navigation.types';

export const items: INavigationItem[] = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    group: 'pages',
    children: [
      {
        title: 'Dashboard',
        icon: 'mdi-circle-medium',
        to: 'dashboard'
      }
    ]
  },
  {
    title: 'Notifications',
    icon: 'mdi-bell',
    group: 'pages',
    children: [
      {
        title: 'Send Notification',
        icon: 'mdi-send',
        to: 'send-notification'
      }
    ]
  }
];
